import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { isLoggedIn } from "../utils/auth"
import { navigate } from "../utils/misc"

const App = ({ data }) => {
  let links = data.allMarkdownRemark.distinct.map((object, i) => {
    let path = object
      .replace(/&/g, "")
      .replace(/ {2}/g, " ")
      .replace(/ /g, "-")
      .toLowerCase()
    path = "/price-list/" + path
    return (
      <div>
        <Link to={path}>{object}</Link>
      </div>
    )
  })

  return <Layout fsOnly={true}>{links}</Layout>
}

export default App

export const query = graphql`
  query ProductCategoryQuery {
    allMarkdownRemark {
      distinct(field: frontmatter___product_category)
    }
  }
`
